.companies-table table {
	@apply w-full h-full border-collapse;
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
}

.companies-table td {
	@apply h-[61px] font-medium border border-solid border-gray-10 px-3 box-border;
}

.companies-table thead > tr > th {
	@apply font-medium text-sm text-gray-40 bg-gray-1 border border-solid border-gray-10 h-7 py-2;
}

.companies-table td:first-child,
.companies-table thead > tr > th:first-child {
	@apply pl-6 pr-[10px];
}

.companies-table td:not(:first-child),
.companies-table thead > tr > th:not(:first-child) {
	@apply px-[20px];
}

.companies-table tbody > tr:hover:not(.rc-table-placeholder) {
	@apply rounded-10 outline outline-[1px] outline-primary-violet -outline-offset-1 cursor-pointer;
}

.companies-table-row-selected {
	@apply bg-gray-10-opacity-50;
}
